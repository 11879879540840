;(function () {
  let timeout: ReturnType<typeof setTimeout> | undefined

  const togglers = Array.from(document.querySelectorAll<HTMLElement>('.toggler'))
  for (const toggler of togglers) {
    // Find the target depending on how it's formatted in the dataset.
    const targetSelector = toggler.dataset.target
    if (!targetSelector) return

    const target = (
      targetSelector.includes('#') ? document.querySelector(targetSelector) : toggler.querySelector(targetSelector)
    ) as HTMLElement | null

    if (!target) return

    target.addEventListener('toggle', () => {
      // Update the toggler's classList to match the target's state.
      if (target.dataset.expanded === 'true') {
        toggler.classList.add('open')
      } else {
        toggler.classList.remove('open')
      }
    })
    toggler.addEventListener('expand', ((e: CustomEvent<{ transitionTime?: number }>) => {
      const transitionTime = typeof e.detail.transitionTime === 'number' ? e.detail.transitionTime : null
      toggle(target, transitionTime, 'open')
    }) as EventListener)
    toggler.addEventListener('contract', ((e: CustomEvent<{ transitionTime?: number }>) => {
      const transitionTime = typeof e.detail.transitionTime === 'number' ? e.detail.transitionTime : null
      toggle(target, transitionTime, 'closed')
    }) as EventListener)
    toggler.addEventListener('click', ((e: CustomEvent<{ transitionTime?: number }>) => {
      const transitionTime = typeof e.detail.transitionTime === 'number' ? e.detail.transitionTime : null
      e.stopPropagation()
      toggle(target, transitionTime)
    }) as EventListener)

    if (toggler.classList.contains('open') && target.classList.contains('collapse')) {
      toggle(target, 0, 'open')
    }
  }

  /**
   * Toggle an element's open or closed state.
   *
   * @param target: The element being toggled.
   * @param transitionTime: The transition time in ms.
   * @param targetState: The target state. Accepts 'open' or 'closed'. Defaults to the opposite of the current state.
   * @return Promise<void>
   */
  function toggle(target: HTMLElement, transitionTime: number | null = null, targetState: string | null = null) {
    const setTo = typeof targetState === 'string' ? targetState : target.dataset.expanded === 'true' ? 'closed' : 'open'
    const vanishes = target.classList.contains('vanishing')

    target.dataset.expanded = (setTo === 'open').toString()
    target.dispatchEvent(new Event('toggle'))

    // Handle target vanishing and/or collapsing as needed.
    if (setTo === 'closed') {
      if (vanishes) {
        setTimeout(() => {
          target.style.setProperty('visibility', 'hidden')
        })
      }

      if (target.classList.contains('collapse')) {
        return contract(target, transitionTime)
      }
    } else {
      if (vanishes) {
        target.style.setProperty('visibility', 'visible')
      }
      if (target.classList.contains('collapse')) {
        return expand(target, transitionTime)
      }
    }
  }

  /**
   * Expand an element's height.
   *
   * @param target: The element being expanded.
   * @param transitionTime: The transition time in ms.
   * @return Promise<void>
   */
  async function expand(target: HTMLElement, transitionTime: number | null = null) {
    const time = typeof transitionTime === 'number' ? transitionTime : getTransitionTime(target)
    target.style.setProperty('max-height', calculateMaxHeight(target))
    target.classList.add('opening')

    return new Promise<void>(resolve => {
      if (timeout) {
        clearTimeout(timeout)
      }
      timeout = setTimeout(() => {
        target.classList.remove('opening')
        if (!target.classList.contains('closing')) {
          target.classList.add('open')
          target.style.setProperty('max-height', 'initial')
        }

        resolve()
      }, time)
    })
  }

  /**
   * Contract an element's height.
   *
   * @param target: The element being contracted.
   * @param transitionTime: The transition time in ms.
   * @return Promise<void>
   */
  function contract(target: HTMLElement, transitionTime: number | null = null) {
    const time = typeof transitionTime === 'number' ? transitionTime : getTransitionTime(target)
    // Use a small delay to make sure the new max-height goes into effect before collapsing.
    const delay = 5

    target.style.setProperty('max-height', calculateMaxHeight(target))

    return new Promise<void>(resolve => {
      if (timeout) {
        clearTimeout(timeout)
      }
      timeout = setTimeout(() => {
        target.classList.remove('opening')
        target.classList.remove('open')
        target.classList.add('closing')
        target.style.setProperty('max-height', '0')

        setTimeout(() => {
          target.classList.remove('closing')
          resolve()
        }, time - delay)
      }, delay)
    })
  }

  /**
   * Determine the optimal height of an element.
   *
   * @param target : The element for which height is being calculated.
   * @returns The calculated max height of the element, represented a string consisting of the value and the unit (px).
   */
  function calculateMaxHeight(target: HTMLElement): string {
    const maxHeight = Array.from(target.children).reduce((acc, el) => {
      acc += parseFloat(getComputedStyle(el).height)

      return acc
    }, 0)

    return `${maxHeight}px`
  }

  /**
   * Determine the transition time for the animation.
   *
   * @param target : The element for which transition time is being calculated.
   * @returns The transition time in ms.
   */
  function getTransitionTime(target: HTMLElement): number {
    const transitionProp = getComputedStyle(target).getPropertyValue('--transition-time')
    const transitionTime = transitionProp ? parseFloat(transitionProp) * 1000 : 300

    return transitionTime
  }
})()
